<template>
    <div class="news">
        <topnav></topnav>
<!--        {{Category}}-->
<!--        {{allCategory}}-->
        <div class="top_main" v-bind:style="{backgroundImage:'url(' + backimg + ')'}">

        </div>
        <div class="global_name">{{site_name}}</div>
        <div id="main">
            <div class="navigation">
                <a-breadcrumb>
                    <a-breadcrumb-item v-for="(data,index) in returnInfo" :key="data.id"><a :href="'/#' + data.jumpurl">{{data.name}}</a></a-breadcrumb-item>
<!--                    <a-breadcrumb-item>{{articleinfo.title}}</a-breadcrumb-item>-->
                </a-breadcrumb>
            </div>
            <ul>
                <li :key="item.id" v-for="(item,index) in articlelist">

                    <div class="left">
                        <!--                        {{item.article_category_id}}-->
                        {{JSON.parse(item.model_info_json).article_category_selectedOptions[JSON.parse(item.model_info_json).article_category_selectedOptions.length-1]}}
                    </div>
                    <div class="right">
                        <!--                        {{item}}-->
                        <h1> {{item.title}}</h1>
                        <!--                        <tag>热招职位</tag>-->
                        <div class="Introduction">
                            {{item.short_content}}
                        </div>
                        <div class="news_cover">
                            <span v-if="item.cover_images == null"></span>
                            <span v-if="item.cover_images !== null"><img :src="server_url+item.cover_images"></span>
                            <div class="readmore">
                                <router-link :to="'/article?aid='+ item.id +'&pid='+ pid ">阅读全文</router-link>
                            </div>
                        </div>

                    </div>
                </li>
                <!--                <li>-->
                <!--                    <div class="left">-->
                <!--                        白色栏目-->
                <!--                    </div>-->
                <!--                    <div class="right">-->
                <!--                        <h1>新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题新闻标题</h1>-->
                <!--&lt;!&ndash;                        <tag>热招职位</tag>&ndash;&gt;-->
                <!--                        <div class="Introduction">-->
                <!--                            （原标题：苏州酒店坍塌已致1死7伤，另有10人失联）-->

                <!--                            最新消息：据@中国消防7月12日消息，今晚8:45，江苏消防救援总队救援人员又搜救出一名被困人员，送往医院治疗。-->

                <!--                            而根据此前救援工作进展，如今已救出被困人员数已经升至9人，其中1人死亡，另有9人失联。-->
                <!--                            @苏州发布7月12日消息，据今吴江消息：7月12日15时33分许，苏州市吴江区松陵街道油车路188号四季开源酒店辅房发生坍塌。-->
                <!--                            江苏省委书记娄勤俭、省长吴政隆作出批示，要求科学组织搜救，全力救治伤员，最大限度减少人员伤亡，防止发生次生灾害。省长吴政隆、常务副省长樊金龙以及苏州市、吴江区主要领导第一时间赶赴现场组织指挥救援。截至18时40分，已救出被困人员8人，其中1人死亡，4人生命体征平稳，3人正在抢救。另有失联人员10人。救援正在全力进行，事故原因正在调查中。-->
                <!--                            据澎湃新闻7月12日报道，7月12日下午，苏州市吴江区一家酒店发生倒塌事故。经初步了解，系该酒店部分房间坍塌，涉及登记住宿的13个房间15人，被困人数正在核查中，现场已全力开展救援工作。-->

                <!--                            据央视新闻报道，据当地政府消息，7月12日下午，江苏苏州市吴江区松陵镇油车路188号四季开源酒店发生倒塌事故。-->

                <!--                            经初步了解，有被困人员，数量正在核查中，现场公安消防等已全力开展救援工作。记者正在赶往事发途中。-->
                <!--                        </div>-->
                <!--&lt;!&ndash;                        <div class="readmore">阅读全文</div>&ndash;&gt;-->
                <!--                        <div class="news_cover"><img src="../../public/news_1.png"></div>-->

                <!--                    </div>-->
                <!--                </li>-->

            </ul>
        </div>
        <bottomnav class="bottom"></bottomnav>
    </div>
</template>

<script>
    import Config from "../config";

    export default {
        name: 'news',
        components: {},
        data() {
            return {
                articlelist: [],
                Category: [],
                // allCategory: [],
                allCategory: Config.data.Category,
                indexinfo: [],
                loading: false,
                returnInfo: [],
                pid: this.$route.query.id,
                server_url: Config.data.server_url,
                site_name: Config.data.site_name,
                backimg: "",
                // current: ['7'],
            }
        },
        created() {
            // 获取头部菜单内容
            this.getList(this.pid, "1", "10", "", "", "");
            this.getCategory(this.pid);
            // this.getallCategory();

        },
        watch: {
            '$route': 'getPath'
        },
        methods: {
            getPath() {
                console.log(this.$route.query.id);
                location.reload()

            },
            async getListurl(id, findObj) {

                let count = 0;
                let k = "";
                for (let i in findObj) {
                    if (findObj[i]["id"] == id) {
                        count++;
                        k = i
                    }
                }
                if (count > 0) {
                    //找到了
                    //把找对信息压倒对象
                    let modelInfoJson = findObj[k]["model_info_json"];
                    let jumpurl = "";
                    if (modelInfoJson) {
                        let temJson = JSON.parse(modelInfoJson);
                        jumpurl = temJson["jumpurl"]
                    } else {
                        jumpurl = "";
                    }
                    let rdata = {
                        id: findObj[k]["id"],
                        name: findObj[k]["title"],
                        jumpurl: jumpurl
                    }
                    this.returnInfo.unshift(rdata);
                    return true
                } else {
                    //没找到从儿子找
                    for (let i in findObj) {

                        let rData = this.getListurl(id, findObj[i]["child"])
                        // console.log("*********************")
                        // console.log(rData)
                        if (rData) {
                            // console.log("*********************")
                            // console.log(rData)
                            // console.log(i)
                            // console.log(findObj)
                            let modelInfoJson = findObj[i]["model_info_json"];
                            let jumpurl = "";
                            if (modelInfoJson) {
                                let temJson = JSON.parse(modelInfoJson);
                                jumpurl = temJson["jumpurl"]
                            } else {
                                jumpurl = "";
                            }
                            let rdata = {
                                id: findObj[i]["id"],
                                name: findObj[i]["title"],
                                jumpurl: jumpurl
                            }
                            this.returnInfo.unshift(rdata);
                            return true
                        }
                    }

                }
                return false
            },
            async getCategory(pid) {

                this.loading = true;
                this.Category = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        p_id: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.backimg = this.server_url + JSON.parse(this.Category.model_info_json).backimg;
                    this.getListurl(this.pid, this.allCategory);
                    this.loading = false;
                }

                this.loading = false;
            },
            async getallCategory() {

                // this.loading = true;
                this.allCategory = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.allCategory = result.data;
                    this.getListurl(this.pid, this.allCategory);
                    // this.loading = false;
                }

                // this.loading = false;
            },
            async getList(article_category_id, page, limit, search, start_time, end_time, status) {
                this.loading = true;
                this.articlelist = [];

                let now = Date.now();
                let result = await this.$get("/api/app/article/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        page: page,
                        limit: 10,
                        search: search,
                        start_time: start_time,
                        end_time: end_time,
                        status: status,
                        article_category_id: article_category_id,
                        t: now,
                    }
                });

                if (result.status === true) {
                    // console.log(result.data.list)
                    this.articlelist = result.data.list;
                    this.loading = false;
                }

                this.loading = false;
            },
            // async getindex(pid) {
            //     this.loading = true;
            //     this.indexinfo = [];
            //
            //     let now = Date.now();
            //     let result = await this.$get("/api/app/indexContent/get", {
            //         params: {
            //             // token: Storage.token,
            //             // uid: Storage.uid,
            //             pid: pid,
            //             t: now,
            //         }
            //     });
            //
            //     if (result.status === true) {
            //
            //         this.indexinfo = result.data;
            //         this.loading = false;
            //     }
            //
            //     this.loading = false;
            // },
        }
    }
</script>
<style scoped>
    .news {
        width: 1154px;
        display: block !important;
        background-color: #1b191a;
    }

    .navigation {
        padding-top: 30px;
        padding-left: 60px;
        font-size: 14px;
        color: #6c6a6b;
    }

    .top_main {

        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        max-height: 440px;
        height: 440px;
        display: block !important;
    }

    .global_name {
        font-family: 微软雅黑;

        font-weight: normal;
        font-size: 16px;
        padding: 15px;
        padding-top: 18px;
        color: #f9fbf8;
        background-color: #221816;
    }

    #main {
        background-color: white;
        height: 100%;
    }

    #main h1 {
        padding: 0;
        margin: 0;
        font-weight: normal;
        margin-bottom: 20px;
        color: #4e0002;
    }

    #main ul {
        padding: 0;
        margin: 0;
        list-style: none;
        padding-top: 40px;
    }

    #main ul li {
        display: flex;
        justify-content: flex-start;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 20px;
        font-size: 14px;

    }

    .left {
        width: 268px;
        padding-top: 0px;
        color: #c5756a;
        font-size: 20px;
        font-weight: 500;
    }

    .right {
        padding-left: 30px;
        width: 600px;
        overflow: hidden;

    }

    .Introduction {
        word-wrap: break-word;
        line-height: 180%;
        color: #888890;
    }

    .readmore {
        margin-top: 20px;
        color: #c5756a;
        margin-bottom: 20px;
    }

    .news_cover {
        padding-bottom: 0px;
        padding-top: 20px;
        border-bottom: #dddddd solid 1px;
    }

    .news_cover img {
        width: 100%;
    }

</style>