<template>
    <div class="news">
        <topnav></topnav>
        <div class="top_main" v-bind:style="{backgroundImage:'url(' + backimg + ')'}">

        </div>
        <div class="global_name">{{site_name}}</div>
        <div id="main">
            <div class="navigation">
                <a-breadcrumb>
                    <a-breadcrumb-item v-for="(data,index) in returnInfo" :key="data.id"><a :href="'/#' + data.jumpurl">{{data.name}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item>{{articleinfo.title}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="articleinfo">

<!--                <div class="left">-->
<!--&lt;!&ndash;                    {{JSON.parse(articleinfo.model_info_json).article_category_selectedOptions[JSON.parse(articleinfo.model_info_json).article_category_selectedOptions.length-1]}}&ndash;&gt;-->

<!--                </div>-->
                <div class="right">
<!--                    {{articleinfo}}-->
                    <h1> {{articleinfo.title}}</h1>
<!--                    <small><span style="margin-right: 20px">{{articleinfo.create_time | dateFormat('YYYY-MM-DD HH:mm')}}</span> <span>作者: {{articleinfo.author}}</span></small>-->
                    <!--                        <tag>热招职位</tag>-->
                    <div class="Introduction">
                        <div class="ql-editor" v-html="articleinfo.content"></div>
<!--                        <div class="ql-editor">-->
<!--                            {{articleinfo.content}}-->
<!--                        </div>-->
                    </div>

                </div>
            </div>
            <div class="Comments" v-show="aid == 34">
                <h2>发表评论</h2>
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="">
                        <a-input v-model="form.name" class="input" placeholder="姓名:"/>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-input v-model="form.mail" class="input" placeholder="联系方式(Tel/E-mail:"/>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-input v-model="form.textarea" type="textarea" class="input" placeholder="评论" style="min-height: 200px"/>
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 0 }">
                        <a-button type="primary" @click="onSubmit" class="butt input"  >
                            发表评论
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </div>
        <bottomnav class="bottom"></bottomnav>
    </div>
</template>

<script>
    import Config from "../config";
    import {Modal} from "ant-design-vue";

    export default {
        name: 'news',
        components: {},
        data() {
            return {
                articleinfo: [],
                Category: [],
                allCategory: [],
                indexinfo: [],
                labelCol: { span: 4 },
                wrapperCol: { span: 14 },
                loading: false,
                returnInfo: [],
                pid: this.$route.query.pid,
                aid: this.$route.query.aid,
                server_url: Config.data.server_url,
                site_name: Config.data.site_name,
                backimg: "",
                form:{
                    name:"",
                    mail:"",
                    textarea:"",
                }
                // current: ['7'],
            }
        },
        created() {
            // 获取头部菜单内容
            this.getarticle(this.aid);
            // this.getCategory(this.pid);
            this.getallCategory();

        },
        watch: {
            '$route': 'getPath'
        },
        methods: {
            getPath() {
                console.log(this.$route.query.id);
                location.reload()

            },
            async getListurl(id, findObj) {

                let count = 0;
                let k = "";
                for (let i in findObj) {
                    if (findObj[i]["id"] == id) {
                        count++;
                        k = i
                    }
                }
                if (count > 0) {
                    //找到了
                    //把找对信息压倒对象
                    let modelInfoJson = findObj[k]["model_info_json"];
                    let jumpurl = "";
                    if (modelInfoJson) {
                        let temJson = JSON.parse(modelInfoJson);
                        jumpurl = temJson["jumpurl"]
                    } else {
                        jumpurl = "";
                    }
                    let rdata = {
                        id: findObj[k]["id"],
                        name: findObj[k]["title"],
                        jumpurl: jumpurl
                    }
                    this.returnInfo.unshift(rdata);
                    return true
                } else {
                    //没找到从儿子找
                    for (let i in findObj) {

                        let rData = this.getListurl(id, findObj[i]["child"])
                        // console.log("*********************")
                        // console.log(rData)
                        if (rData) {
                            // console.log("*********************")
                            // console.log(rData)
                            // console.log(i)
                            // console.log(findObj)
                            let modelInfoJson = findObj[i]["model_info_json"];
                            let jumpurl = "";
                            if (modelInfoJson) {
                                let temJson = JSON.parse(modelInfoJson);
                                jumpurl = temJson["jumpurl"]
                            } else {
                                jumpurl = "";
                            }
                            let rdata = {
                                id: findObj[i]["id"],
                                name: findObj[i]["title"],
                                jumpurl: jumpurl
                            }
                            this.returnInfo.unshift(rdata);
                            return true
                        }
                    }

                }
                return false
            },
            async getCategory(pid) {

                this.loading = true;
                this.Category = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        p_id: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.backimg = this.server_url + JSON.parse(this.Category.model_info_json).backimg;
                    this.loading = false;
                }

                this.loading = false;
            },
            async getallCategory() {

                // this.loading = true;
                this.allCategory = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.allCategory = result.data;
                    this.getListurl(this.pid, this.allCategory);
                    // this.loading = false;
                }

                // this.loading = false;
            },
            async getarticle(id) {
                this.loading = true;
                this.articleinfo = [];

                let now = Date.now();
                let result = await this.$get("/api/app/article/get", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        id: id,
                        t: now,
                    }
                });

                if (result.status === true) {
                    // console.log(result.data.list)
                    this.articleinfo = result.data;
                    this.backimg = this.server_url + this.articleinfo.cover_images;

                    this.loading = false;
                }

                this.loading = false;
            },
            // async onSubmit() {
            //     console.log('submit!', this.form);
            // },
            async onSubmit() {


                if (this.form.name =="") {
                    Modal.warning({
                        title: "请输入姓名"
                    });

                    return
                }
                if (this.form.mail =="") {
                    Modal.warning({
                        title: "请输入联系方式"
                    });

                    return
                }


                let now = Date.now();
                let result = await this.$post("/api/app/articleComments/create", {
                    // uid: Storage.uid,
                    // token: Storage.token,
                    save_value: {
                        content: this.form.textarea.toString(),
                        contact_info: this.form.mail.toString(),
                        contact_name: this.form.name.toString(),
                        article_id: this.aid,
                        t: now,
                    },
                });

                if (result.status === true) {
                    Modal.info({
                        title: "发送成功!我们将会在近期根据您的联系信息联络您."
                    });
                }
            },
        }
    }
</script>
<style scoped>
    .news {
        width: 1154px;
        display: block !important;
        background-color: #1b191a;
    }

    .navigation {
        padding-top: 30px;
        padding-left: 60px;
        font-size: 14px;
        color: #6c6a6b;
    }

    .top_main {

        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        max-height: 440px;
        height: 440px;
        display: block !important;
    }

    .global_name {
        font-family: 微软雅黑;

        font-weight: normal;
        font-size: 16px;
        padding: 15px;
        padding-top: 18px;
        color: #f9fbf8;
        background-color: #221816;
    }

    #main {
        background-color: white;
        height: 100%;
    }

    #main h1 {
        padding: 0;
        margin: 0;
        font-weight: normal;
        margin-bottom: 20px;
        color: #4e0002;
    }

    #main ul {
        padding: 0;
        margin: 0;
        list-style: none;
        padding-top: 40px;
    }

    #main ul li {
        display: flex;
        justify-content: flex-start;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 20px;
        font-size: 14px;

    }

    .left {
        width: 268px;
        padding-top: 0px;
        color: #c5756a;
        font-size: 20px;
        font-weight: 500;
    }

    .right {
        /*padding-left: 30px;*/
        padding-top: 40px;
        width: 800px;
        overflow: hidden;
        margin: 0 auto;

    }
    .right img{
        width: 800px;
    }

    .Introduction {
        word-wrap: break-word;
        line-height: 220%;
        color: #54565b;
        min-height: 700px;
        border-top: #dddddd 1px solid;
        margin-top: 30px;
    }
    small{
        color: #999;
        margin-bottom: 23px;
        font-size: 14px;

    }
    .Introduction /deep/ p{
        line-height: 220%;
        text-indent: 2em;
        margin-bottom: 10px;
    }

    .readmore {
        margin-top: 20px;
        color: #c5756a;
        margin-bottom: 20px;
    }

    .news_cover {
        padding-bottom: 0px;
        padding-top: 20px;
        border-bottom: #dddddd solid 1px;
    }

    .news_cover img {
        width: 100%;
    }

    .articleinfo {
        color: #221816;
        display: flex;
        justify-content: flex-start;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 20px;
        font-size: 14px;
    }
    .Comments{
        padding-top: 40px;
        padding-bottom: 40px;
        width: 800px;
        overflow: hidden;
        margin: 0 auto;
        word-wrap: break-word;
        line-height: 220%;
        color: #54565b;
        border-top: #dddddd 1px solid;

    }
    .Comments .input{
        border: rgb(202,38,26) 1px solid;
    }
    .butt{
        background-color: rgb(202,38,26);
        color: #dddddd;
    }
</style>