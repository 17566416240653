<template>
    <div class="news">
        <topnav></topnav>
<!--        <div class="top_main" v-bind:style="{backgroundImage:'url(' + backimg + ')'}">-->

<!--        </div>-->
<!--        <div class="global_name">{{site_name}}</div>-->
        <div id="main">
            <div class="navigation">
                <a-breadcrumb>
                    <a-breadcrumb-item v-for="(data,index) in returnInfo" :key="data.id"><a :href="'/#' + data.jumpurl">{{data.name}}</a></a-breadcrumb-item>
                    <a-breadcrumb-item>{{articleinfo.title}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
<!--            {{articleinfo}}-->
            <div class="articleinfo">

                <div class="posters">
                    <span v-if="articleinfo.cover_images == null"></span>
                    <span v-if="articleinfo.cover_images !== null"><img :src="server_url+articleinfo.cover_images" height="376" width="268"></span>
                    <br/>
                    <small>{{JSON.parse(articleinfo.model_info_json).copyright}} </small>

                </div>
                <div class="producer">

                    <h1>
                        {{articleinfo.title}}
                    </h1>
                    <SMALL CLASS="small">{{JSON.parse(articleinfo.model_info_json).subtitle}} </SMALL>
                    <div>
                        <span class="line" v-for="(data,index) in JSON.parse(articleinfo.model_info_json).FormModel.content_value" :key="index"><span class="left">{{data.name}}</span><span class="right"> {{data.value}}</span></span>

                    </div>
                </div>

            </div>
            <div class="tabpane">

                <a-tabs :default-active-key=type @change="callback">
<!--                    {{type}}-->
                    <a-tab-pane key="1" tab="作品静帧" >
                        <div class="imggroup">
                            <ul>
                                <li  v-for="(data,index) in JSON.parse(articleinfo.model_info_json).imggroup" :key="index">
                                    <img :src="server_url+data">
                                </li>
                            </ul>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="视频">
                        <div class="playurl">
                            <div v-html="JSON.parse(articleinfo.model_info_json).playurl"></div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="影片介绍">
                        <div class="Introduction">
                            <div class="ql-editor" v-html="articleinfo.content"></div>
                            <!--                        <div class="ql-editor">-->
                            <!--                            {{articleinfo.content}}-->
                            <!--                        </div>-->
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <bottomnav class="bottom"></bottomnav>
    </div>
</template>

<script>
    import Config from "../config";

    export default {
        name: 'news',
        components: {},
        data() {
            return {
                articleinfo: [],
                Category: [],
                allCategory: [],
                indexinfo: [],
                loading: false,
                returnInfo: [],
                pid: this.$route.query.pid,
                aid: this.$route.query.aid,

                type: this.$route.query.type,

                server_url: Config.data.server_url,
                site_name: Config.data.site_name,
                backimg: "",
                // current: ['7'],
            }
        },
        created() {
            // 获取头部菜单内容
            this.getarticle(this.aid);
            // this.getCategory(this.pid);
            this.getallCategory();
            if (this.type == ""){
                this.type = 1
            }

        },
        watch: {
            '$route': 'getPath'
        },
        methods: {
            getPath() {
                console.log(this.$route.query.id);
                location.reload()

            },
            async getListurl(id, findObj) {

                let count = 0;
                let k = "";
                for (let i in findObj) {
                    if (findObj[i]["id"] == id) {
                        count++;
                        k = i
                    }
                }
                if (count > 0) {
                    //找到了
                    //把找对信息压倒对象
                    let modelInfoJson = findObj[k]["model_info_json"];
                    let jumpurl = "";
                    if (modelInfoJson) {
                        let temJson = JSON.parse(modelInfoJson);
                        jumpurl = temJson["jumpurl"]
                    } else {
                        jumpurl = "";
                    }
                    let rdata = {
                        id: findObj[k]["id"],
                        name: findObj[k]["title"],
                        jumpurl: jumpurl
                    }
                    this.returnInfo.unshift(rdata);
                    return true
                } else {
                    //没找到从儿子找
                    for (let i in findObj) {

                        let rData = this.getListurl(id, findObj[i]["child"])
                        // console.log("*********************")
                        // console.log(rData)
                        if (rData) {
                            // console.log("*********************")
                            // console.log(rData)
                            // console.log(i)
                            // console.log(findObj)
                            let modelInfoJson = findObj[i]["model_info_json"];
                            let jumpurl = "";
                            if (modelInfoJson) {
                                let temJson = JSON.parse(modelInfoJson);
                                jumpurl = temJson["jumpurl"]
                            } else {
                                jumpurl = "";
                            }
                            let rdata = {
                                id: findObj[i]["id"],
                                name: findObj[i]["title"],
                                jumpurl: jumpurl
                            }
                            this.returnInfo.unshift(rdata);
                            return true
                        }
                    }

                }
                return false
            },
            async getCategory(pid) {

                this.loading = true;
                this.Category = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        p_id: pid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.Category = result.data;
                    this.backimg = this.server_url + JSON.parse(this.Category.model_info_json).backimg;
                    this.loading = false;
                }

                this.loading = false;
            },
            async getallCategory() {

                // this.loading = true;
                this.allCategory = [];

                let now = Date.now();
                let result = await this.$get("/api/app/articleCategory/list", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        t: now,
                    }
                });

                if (result.status === true) {

                    this.allCategory = result.data;
                    this.getListurl(this.pid, this.allCategory);
                    // this.loading = false;
                }

                // this.loading = false;
            },
            async getarticle(id) {
                this.loading = true;
                this.articleinfo = [];

                let now = Date.now();
                let result = await this.$get("/api/app/article/get", {
                    params: {
                        // token: Storage.token,
                        // uid: Storage.uid,
                        id: id,
                        t: now,
                    }
                });

                if (result.status === true) {
                    // console.log(result.data.list)
                    this.articleinfo = result.data;
                    this.loading = false;
                }

                this.loading = false;
            },
            callback(key) {
                console.log(key);
            },
            // async getindex(pid) {
            //     this.loading = true;
            //     this.indexinfo = [];
            //
            //     let now = Date.now();
            //     let result = await this.$get("/api/app/indexContent/get", {
            //         params: {
            //             // token: Storage.token,
            //             // uid: Storage.uid,
            //             pid: pid,
            //             t: now,
            //         }
            //     });
            //
            //     if (result.status === true) {
            //
            //         this.indexinfo = result.data;
            //         this.loading = false;
            //     }
            //
            //     this.loading = false;
            // },
        }
    }
</script>
<style scoped>
    .news {
        width: 1154px;
        display: block !important;
        background-color: #1b191a;
    }

    .navigation {
        padding-top: 30px;
        padding-left: 60px;
        font-size: 14px;
        color: #6c6a6b;
    }

    .top_main {

        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        max-height: 440px;
        height: 440px;
        display: block !important;
    }

    .global_name {
        font-family: 微软雅黑;

        font-weight: normal;
        font-size: 16px;
        padding: 15px;
        padding-top: 18px;
        color: #f9fbf8;
        background-color: #221816;
    }

    #main {
        background-color: white;
        height: 100%;
    }

    #main h1 {
        padding: 0;
        margin: 0;
        font-weight: normal;
        margin-bottom: 20px;
        color: #4e0002;
    }


    /*.left {*/
    /*    width: 268px;*/
    /*    padding-top: 0px;*/
    /*    color: #c5756a;*/
    /*    font-size: 20px;*/
    /*    font-weight: 500;*/
    /*}*/

    /*.right {*/
    /*    !*padding-left: 30px;*!*/
    /*    padding-top: 40px;*/
    /*    width: 800px;*/
    /*    overflow: hidden;*/
    /*    margin: 0 auto;*/

    /*}*/
    /*.right img{*/
    /*    width: 800px;*/
    /*}*/

    .Introduction {
        word-wrap: break-word;
        line-height: 220%;
        color: #54565b;
        min-height: 700px;
        /*border-top: #dddddd 1px solid;*/
        margin-top: 30px;
    }
    .tabpane{
        width: 1050px;
        margin:  0px auto;
        min-height: 650px;
    }
    .imggroup{}
    .imggroup ul{padding: 0;margin: 0;}
    .imggroup li{ width: 24%; margin-right: 1.3%; float: left;list-style: none}
    .imggroup li:nth-child(4n){ margin-right: 0%;}
    .imggroup li img{width: 100%;height: auto;margin: 5px;}
    .tabpane /deep/ .ant-tabs-nav {
        border-color: transparent;
        background: transparent;
        left: 50%!important;
        margin-left: -150px;
    }
    .playurl {
        width: 1050px;
        margin:  0px auto;
    }
    .playurl /deep/ iframe{
        width: 1050px;
        height:650px;

    }
    .small{
        color: #999;
        margin-bottom: 23px;
        font-size: 14px;

    }
    .Introduction /deep/ p{
        line-height: 220%;
        text-indent: 2em;
        margin-bottom: 10px;
    }

    .readmore {
        margin-top: 20px;
        color: #c5756a;
        margin-bottom: 20px;
    }

    .news_cover {
        padding-bottom: 0px;
        padding-top: 20px;
        border-bottom: #dddddd solid 1px;
    }

    .news_cover img {
        width: 100%;
    }

    .articleinfo {
        color: #221816;
        display: flex;
        justify-content: flex-start;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 20px;
        font-size: 14px;
    }
    .posters {
        width: 268px;
        padding-top: 8px;
    }

    .producer {
        padding-left: 30px;

    }

    .producer .left {
        font-weight: bold;
        width: 160px;
        padding-right: 20px;
        display: block!important;
        text-align: right;

    }
    .producer .right {
        display: block;
        width: 560px;
    }

    .producer .line {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        font-size: 13px;
        line-height: 180%;
        color: #363a3d;

    }
    small{color: #363a3d;}
</style>